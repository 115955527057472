import styled from 'styled-components';

import { media } from 'styles/utils';

export const ErrorPageContainer = styled.div`
  padding: 48px 0;
  max-width: 864px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    padding: 96px 0;
  `}

  ${media.large`
    padding: 192px 0;
  `}
`;
