import * as i from 'types';
import { EntryCollectionWithLinkResolutionAndWithoutUnresolvableLinks } from 'contentful';

import { useRouter } from 'hooks';
import { getMainNavigation, getFooter } from 'services/contentful';
import contentfulClient from 'services/contentful/contentfulClient';
import TrianglesIcon from 'vectors/triangles.svg';
import { Button } from 'common/interaction';
import { Group } from 'common/layout';
import { Heading, Paragraph } from 'common/typography';
import PageLayout from 'layouts/PageLayout';
import { ErrorPageContainer } from 'modules/errorPages';

const NotFound: i.NextPageComponent<NotFoundProps> = () => {
  const router = useRouter();

  return (
    <ErrorPageContainer>
      <TrianglesIcon />
      <Heading margin="48px 0">Oeps, iets ging mis</Heading>
      <Paragraph margin="0 0 48px" align="center">
        De pagina waar je naar op zoek bent lijkt momenteel niet vindbaar. Wellicht kan je proberen
        te vinden wat je zoekt via de homepagina, of teruggaan naar de vorige pagina.
      </Paragraph>
      <Group gap={24}>
        <Button variant="secondary" onClick={() => router.back()}>
          Terug
        </Button>
        <Button buttonType="link" href="/">
          Home
        </Button>
      </Group>
    </ErrorPageContainer>
  );
};

type NotFoundProps = {
  pages: EntryCollectionWithLinkResolutionAndWithoutUnresolvableLinks<i.TypePageGeneralFields>;
};

export const getStaticProps = async () => {
  const pages = await contentfulClient.withoutUnresolvableLinks.getEntries<i.TypePageGeneralFields>(
    {
      content_type: 'pageGeneral',
    },
  );
  const navigation = await getMainNavigation();
  const footer = await getFooter();

  return {
    props: {
      pages,
      navigation,
      footer,
    },
  };
};

NotFound.layout = (page, pageProps) => {
  return (
    <PageLayout navigation={pageProps.navigation} footer={pageProps.footer} withoutPadding>
      {page}
    </PageLayout>
  );
};

export default NotFound;
